//Copied (then modified) from the portalapi project, as an example

// export * from './app.config';

export const apiUrl = 'https://boardsapitest.visionslive.com/';
export const vlqrApiUrl = 'https://apitest.visionslive.com/VL';
export const clientUrl = 'https://clienttest.visionslive.com';

//export const changeCrabProjectId = 'XXXXazUgO1';
//export const changeCrabUrl = 'https://visionslivetest.changecrab.com/';

//export const growthbookApiKey = 'XXXXsdk-hgLglYXECUeU4Fu1';
//export const growthbookSecretKey = 'XXXXzbfQUZrXcfZGamMpsVEVqQ==';